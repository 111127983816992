
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceCounterThunk,
  selectTargetPageState,
} from "../../slice";
import { Col, Row } from "antd";
import { TabContainer } from "../../components/tab-container";
import "./styles.scss";
import CardCounter from "../../components/card-counter";
import { ServicesEnum } from "../../../../../../utils/services";
import CardCounterScore from "../../components/card-counter-score";
import { useLocation } from "react-router";
import CardCounterImage from "../../components/card-video";
import { selectReportPageState } from "../../../report-page/slice";
import Icons from "../../../../../../common/components/Icons";
import { DashboardOutlined, FundViewOutlined, QuestionCircleOutlined } from "@ant-design/icons";


const TabContentResumo = () => {
  const state = useSelector(selectTargetPageState);
  const reportState = useSelector(selectReportPageState);
  const dispatch = useDispatch();
  const location = useLocation()

  const buildLink = (tabKey) => {
    return `${location.pathname}?tab=${tabKey}`
  }

  const parseDate = (dateStr) => {
    const [data, hora] = dateStr.split(" ");
    const [dia, mes, ano] = data.split("/").map(Number);
    const [horas, minutos] = hora.split(":").map(Number);
    
    return new Date(ano, mes - 1, dia, horas, minutos)
  }

  const columns: any[] = useMemo(() => (state.data.target ? [
    {
      title: (
        <>
          Orientações
        </>
      ),
      can_render: true,
      services: [
        ServicesEnum.parecer.name,
      ],
      render: (services) => {
        
        return (
          <Row gutter={[16, 16]} justify="start">
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounterImage
                title={"Como ler a pesquisa?"}
                image={<Icons icon="orientations" />}
                toLink={buildLink("orientacoes")}
                labelLink={"Ver tudo"}
              />
            </Col>
            {["smart_pro", "smart"].includes(reportState.data.report?.case_type ?? "") ? (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounterImage
                  title={"Vídeo de apresentação"}
                  image={<FundViewOutlined/>}
                  toLink={buildLink("orientacoes")}
                  labelLink={"Ver tudo"}
                />
              </Col>
            ) : null}
            {["smart_pro", "simples", "smart", "preventivo"].includes(reportState.data.report?.case_type ?? "") ? (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounterImage
                  title={"Grafos"}
                  image={<Icons icon="graph" />}
                  toLink={`/report/${reportState.data.report?.id}/graph`}
                  labelLink={"Ver tudo"}
                />
              </Col>
            ) : null}
             {services?.parecer && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounterImage
                title={"Parecer preventivo"}
                image={<Icons icon="parecer" />}
                toLink={buildLink(ServicesEnum.parecer.parecer.name)}
                labelLink={"Ver tudo"}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: (
        <>
          Processos
        </>
      ),
      can_render: Boolean(state.data.target?.services?.processos),
      services: [ServicesEnum.processos.name],
      render: (services) => {

        return (
          <Row gutter={[16, 16]} justify="start">
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Processos relevantes"}
                highlightColor={undefined}
                qtd={services.processos?.counters?.processos_relevantes_qtd}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink(ServicesEnum.processos.processos_para_aquisicao_copia.name)}
                labelLink={"Ver tudo"}
                currencyValue={services.processos?.counters?.processos_relevantes_total_value}
              />
            </Col>
            )}
            {(services?.processos && reportState.data?.report?.case_type === "preventivo") && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounterImage
                title={"Averiguação sobre os processos"}
                image={<Icons icon="text-center" />}
                toLink={buildLink(ServicesEnum.processos.averiguacao_processos.name)}
                labelLink={"Ver tudo"}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: (
        <>
          Processos <strong>em tramitação</strong> buscados por nome
        </>
      ),
      can_render: Boolean(state.data.target?.services?.processos),
      services: [ServicesEnum.processos.name],
      render: (services) => {
        const metrics = services.processos?.counters?.data?.processos_ativos

        return (
          <Row gutter={[16, 16]} justify="start">
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Polo passivo"}
                highlightColor="red"
                qtd={metrics?.qtd_por_nome?.polo_passivo}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_tramitacao")}
                labelLink={"Ver tudo"}
                currencyValue={metrics?.valor_por_nome?.polo_passivo}
              />
            </Col>
            )}
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Polo ativo"}
                highlightColor="green"
                qtd={metrics?.qtd_por_nome?.polo_ativo}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_tramitacao")}
                labelLink={"Ver tudo"}
                currencyValue={metrics?.valor_por_nome?.polo_ativo}
              />
            </Col>
            )}
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Indireto, incidente ou recurso"}
                qtd={(
                  (metrics?.qtd_por_nome?.incidente ?? 0) + 
                  (metrics?.qtd_por_nome?.indireto ?? 0)
                )}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_tramitacao")}
                labelLink={"Ver tudo"}
                currencyValue={(
                  (metrics?.valor_por_nome?.incidente ?? 0) + 
                  (metrics?.valor_por_nome?.indireto ?? 0) 
                )}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: (
        <>
          Processos <strong>sem tramitação, arquivados ou extintos</strong>{" "}
          buscados por nome
        </>
      ),
      services: [ServicesEnum.processos.name],
      can_render: Boolean(state.data.target?.services?.processos),
      render: (services) => {
        const metrics = services.processos?.counters?.data?.processos_inativos

        return (
          <Row gutter={[16, 16]} justify="start">
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Polo passivo"}
                highlightColor="red"
                qtd={metrics?.qtd_por_nome?.polo_passivo}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_arquivados")}
                labelLink={"Ver tudo"}
                currencyValue={metrics?.valor_por_nome?.polo_passivo}
              />
            </Col>
            )}
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Polo ativo"}
                highlightColor="green"
                qtd={metrics?.qtd_por_nome?.polo_ativo}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_arquivados")}
                labelLink={"Ver tudo"}
                currencyValue={metrics?.valor_por_nome?.polo_ativo}
              />
            </Col>
            )}
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Indireto, incidente ou recurso"}
                qtd={(
                  (metrics?.qtd_por_nome?.incidente ?? 0) + 
                  (metrics?.qtd_por_nome?.indireto ?? 0)
                )}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_arquivados")}
                labelLink={"Ver tudo"}
                currencyValue={(
                  (metrics?.valor_por_nome?.incidente ?? 0) + 
                  (metrics?.valor_por_nome?.indireto ?? 0) 
                )}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: (
        <>
          Processos <strong>em tramitação</strong> buscados por cpf/cnpj
        </>
      ),
      services: [ServicesEnum.processos.name],
      can_render: Boolean(state.data.target?.services?.processos),
      render: (services) => {
        const metrics = services.processos?.counters?.data?.processos_ativos

        return (
          <Row gutter={[16, 16]} justify="start">
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Polo passivo"}
                highlightColor="red"
                qtd={metrics?.qtd_por_cpf_cnpj?.polo_passivo}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_tramitacao")}
                labelLink={"Ver tudo"}
                currencyValue={metrics?.valor_por_cpf_cnpj?.polo_passivo}
              />
            </Col>
            )}
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Polo ativo"}
                highlightColor="green"
                qtd={metrics?.qtd_por_cpf_cnpj?.polo_ativo}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_tramitacao")}
                labelLink={"Ver tudo"}
                currencyValue={metrics?.valor_por_cpf_cnpj?.polo_ativo}
              />
            </Col>
            )}
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Indireto, incidente ou recurso"}
                qtd={(
                  (metrics?.qtd_por_cpf_cnpj?.incidente ?? 0) + 
                  (metrics?.qtd_por_cpf_cnpj?.indireto ?? 0)
                )}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_tramitacao")}
                labelLink={"Ver tudo"}
                currencyValue={(
                  (metrics?.valor_por_cpf_cnpj?.incidente ?? 0) + 
                  (metrics?.valor_por_cpf_cnpj?.indireto ?? 0) 
                )}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: (
        <>
          Processos <strong>sem tramitação, arquivados ou extintos</strong>{" "}
          buscados por cpf/cnpj
        </>
      ),
      services: [ServicesEnum.processos.name],
      can_render: Boolean(state.data.target?.services?.processos),
      render: (services) => {
        const metrics = services.processos?.counters?.data?.processos_inativos

        return (
          <Row gutter={[16, 16]} justify="start">
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Polo passivo"}
                highlightColor="red"
                qtd={metrics?.qtd_por_cpf_cnpj?.polo_passivo}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_arquivados")}
                labelLink={"Ver tudo"}
                currencyValue={metrics?.valor_por_cpf_cnpj?.polo_passivo}
              />
            </Col>
            )}
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Polo ativo"}
                highlightColor="green"
                qtd={metrics?.qtd_por_cpf_cnpj?.polo_ativo}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_arquivados")}
                labelLink={"Ver tudo"}
                currencyValue={metrics?.valor_por_cpf_cnpj?.polo_ativo}
              />
            </Col>
            )}
            {services?.processos && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Indireto, incidente ou recurso"}
                qtd={(
                  (metrics?.qtd_por_cpf_cnpj?.incidente ?? 0) + 
                  (metrics?.qtd_por_cpf_cnpj?.indireto ?? 0)
                )}
                isLoading={services?.processos?.service_status !== "completed"}
                toLink={buildLink("processos_arquivados")}
                labelLink={"Ver tudo"}
                currencyValue={(
                  (metrics?.valor_por_cpf_cnpj?.incidente ?? 0) + 
                  (metrics?.valor_por_cpf_cnpj?.indireto ?? 0) 
                )}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: <>Informações sobre empresas</>,
      can_render: Boolean(state.data.target?.services?.receita_federal || state.data.target?.services?.jucesp),
      services: [
        ServicesEnum.receita_federal.name,
        ServicesEnum.jucesp.name,
      ],
      render: (services) => {
        return (
          <Row gutter={[16, 16]} justify="start">
            {services?.receita_federal && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Empresas que participa (ativas)"}
                qtd={services?.receita_federal?.counters?.empresas_socio_ativas_qtd}
                isLoading={services?.receita_federal?.service_status !== "completed"}
                toLink={buildLink(ServicesEnum.receita_federal.empresas_cpf_cnpj_e_socio.name)}
                labelLink={"Ver tudo"}
                currencyValue={services.receita_federal?.counters?.empresas_socio_ativas_soma_capital}
              />
            </Col>
            )}
            {services?.receita_federal && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Empresários individuais (ativos)"}
                qtd={services.receita_federal?.counters?.empresarios_individuais_confirmados_ativos_qtd}
                isLoading={services?.receita_federal?.service_status !== "completed"}
                toLink={buildLink(ServicesEnum.receita_federal.empresarios_individuais_confirmados_do_cpf.name)}
                labelLink={"Ver tudo"}
                currencyValue={services.receita_federal?.counters?.empresarios_individuais_confirmados_ativos_soma_capital}
              />
            </Col>
            )}
            {services?.receita_federal && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Possíveis empresários individuais (ativos)"}
                qtd={services.receita_federal?.counters?.empresarios_individuais_possiveis_ativos_qtd}
                isLoading={services?.receita_federal?.service_status !== "completed"}
                toLink={buildLink(ServicesEnum.receita_federal.empresarios_individuais_confirmados_do_cpf.name)}
                labelLink={"Ver tudo"}
                currencyValue={services.receita_federal?.counters?.empresarios_individuais_confirmados_ativos_soma_capital}
              />
            </Col>
            )}
            {(services?.receita_federal && state.data.target?.cpf_cnpj?.length == 14) && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Filiais (ativas)"}
                qtd={services?.receita_federal?.counters?.filiais_ativas_qtd}
                isLoading={services?.receita_federal?.service_status !== "completed"}
                toLink={buildLink(ServicesEnum.receita_federal.filiais_do_cnpj.name)}
                labelLink={"Ver tudo"}
                currencyValue={services.receita_federal?.counters?.filiais_ativas_soma_capital}
              />
            </Col>
            )}
            {(services?.jucesp && state.data.target?.cpf_cnpj?.length == 14) && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Atos societários em SP (JUCESP)"}
                qtd={services.jucesp?.counters?.total_empresas_count}
                isLoading={services?.jucesp?.service_status !== "completed"}
                toLink={buildLink(ServicesEnum.jucesp.empresas.name)}
                labelLink={"Ver tudo"}
                currencyValue={services.jucesp?.counters?.total_empresas_sum_capital_social}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: <>Análise de crédito e avaliação financeira</>,
      can_render: Boolean(
        state.data.target?.services?.boavista
        || state.data.target?.services?.cenprot
      ),
      services: [
        ServicesEnum.boavista.name,
        ServicesEnum.cenprot.name,
      ],
      render: (services) => {
        return (
          <Row gutter={[16, 16]} justify="start">
            {services?.boavista && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounterScore
                title={"Score"}
                scoreValue={services.boavista?.counters?.score_risco_6_meses}
                isLoading={services?.boavista?.service_status !== "completed"}
                toLink={buildLink("score_protestos_negativacoes")}
                labelLink={"Ver tudo"}
              />
            </Col>
            )}
            {services?.boavista && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Débitos"}
                qtd={services?.boavista?.counters?.total_debitos_qtd}
                isLoading={services?.boavista?.service_status !== "completed"}
                toLink={buildLink("score_protestos_negativacoes")}
                labelLink={"Ver tudo"}
                currencyValue={services.boavista?.counters?.total_debitos_soma_valor}
              />
            </Col>
            )}
            {services?.boavista && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Protestos (BoaVista)"}
                qtd={services?.boavista?.counters?.protestos_qtd}
                isLoading={services?.boavista?.service_status !== "completed"}
                toLink={buildLink("score_protestos_negativacoes")}
                labelLink={"Ver tudo"}
                currencyValue={services.boavista?.counters?.protestos_soma_valor}
              />
            </Col>
            )}
            {services?.cenprot && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Protestos (CENPROT)"}
                qtd={services?.cenprot?.counters?.total_protestos_count}
                isLoading={services?.cenprot?.service_status !== "completed"}
                toLink={buildLink("score_protestos_negativacoes")}
                labelLink={"Ver tudo"}
                currencyValue={services.cenprot?.counters?.total_protestos_sum_valor}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: <>Presença na internet</>,
      can_render: Boolean(state.data.target?.services?.gnews || state.data.target?.services?.socialmedia || state.data.target?.services?.whois),
      services: [ServicesEnum.gnews.name, ServicesEnum.socialmedia.name, ServicesEnum.whois.name],
      render: (services) => {
        return (
          <Row gutter={[16, 16]} justify="start">
            {/* {services?.gnews && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Notícias"}
                qtd={services?.gnews?.counters?.total_noticias_count}
                isLoading={services?.gnews?.service_status !== "completed"}
                toLink={buildLink("noticias_midias_sites")}
                labelLink={"Ver tudo"}
              />
            </Col>
            )} */}
            {services?.whois && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Sites"}
                qtd={services.whois?.counters?.total_dominios_count}
                isLoading={services?.whois?.service_status !== "completed"}
                toLink={buildLink("noticias_midias_sites")}
                labelLink={"Ver tudo"}
              />
            </Col>
            )}
            {services?.socialmedia && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Mídias sociais"}
                qtd={services?.socialmedia?.counters?.total_social_medias_count}
                isLoading={services?.socialmedia?.service_status !== "completed"}
                toLink={buildLink("noticias_midias_sites")}
                labelLink={"Ver tudo"}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
    {
      title: <>Propriedade intelectual</>,
      can_render: Boolean(state.data.target?.services?.inpi),
      services: [
        ServicesEnum.inpi.name,
      ],
      render: (services) => {
        return (
          <Row gutter={[16, 16]} justify="start">
            {services?.inpi && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Marcas"}
                qtd={services?.inpi?.counters?.total_marcas_count}
                isLoading={services?.inpi?.service_status !== "completed"}
                toLink={buildLink("marcas_patentes")}
                labelLink={"Ver tudo"}
              />
            </Col>
            )}
            {services?.inpi && (
            <Col xs={12} sm={12} md={8} lg={8}>
              <CardCounter
                title={"Patentes"}
                qtd={services?.inpi?.counters?.total_patentes_count}
                isLoading={services?.inpi?.service_status !== "completed"}
                toLink={buildLink("marcas_patentes")}
                labelLink={"Ver tudo"}
              />
            </Col>
            )}
          </Row>
        );
      },
    },
      {
        title: <>Registro de bens e documentos jurídicos</>,
        can_render: Boolean(
          state.data.target?.services?.veiculos 
          || state.data.target?.services?.imoveis
          || ["smart_pro", "smart", "simples", "docs", "mapa_calor"].includes(reportState.data.report?.case_type ?? "")
        ),
        services: [
          ServicesEnum.veiculos.name, 
          ServicesEnum.imoveis.name,
        ],
        render: (services) => {
          return (
            <Row gutter={[16, 16]} justify="start">
              {services?.veiculos && (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounter
                  title={"Veículos"}
                  qtd={services?.veiculos?.counters?.total_veiculos_count}
                  isLoading={services?.veiculos?.service_status !== "completed"}
                  toLink={buildLink(ServicesEnum.veiculos.veiculos.name)}
                  labelLink={"Ver tudo"}
                  currencyValue={(services.veiculos?.counters?.total_veiculos_sum_fipe ?? 0) * 100}
                />
              </Col>
              )}
              {(services?.imoveis && (services.imoveis?.counters?.total_imoveis_count ?? 0) > 0 || parseDate(state.data.target?.created_at) >= new Date("2025-01-10")) ? (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounter
                  title={"Imóveis"}
                  qtd={services?.imoveis?.counters?.total_imoveis_count}
                  isLoading={services?.imoveis?.service_status !== "completed"}
                  toLink={buildLink(ServicesEnum.imoveis.imoveis.name)}
                  labelLink={"Ver tudo"}
                  currencyValue={null}
                />
              </Col>
              ) : ["smart_pro", "smart", "simples", "docs"].includes(reportState.data.report?.case_type ?? "")  ? (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounter
                  title={"Imóveis"}
                  qtd={null}
                  isLoading={false}
                  toLink={buildLink("attachments")}
                  labelLink={"Ver anexos"}
                  currencyValue={null}
                />
              </Col>
              ) : null}
              {["smart_pro", "smart", "mapa_calor"].includes(reportState.data.report?.case_type ?? "")  && (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounter
                  title={"Escrituras e procurações"}
                  qtd={null}
                  isLoading={false}
                  toLink={buildLink("attachments")}
                  labelLink={"Ver anexos"}
                  currencyValue={null}
                />
              </Col>
              )}
            </Row>
          );
        },
      },
      {
        title: <>Outros</>,
        can_render: Boolean(
          state.data.target?.services?.veiculos 
          || state.data.target?.services?.ecosystem 
          || state.data.target?.services?.tst
          || state.data.target?.services?.sintegra),
        services: [
          ServicesEnum.ecosystem.name,
          ServicesEnum.tst.name,
          ServicesEnum.sintegra.name,
        ],
        render: (services) => {
          return (
            <Row gutter={[16, 16]} justify="start">
              {services?.ecosystem && (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounter
                  title={"Pessoas relacionadas"}
                  qtd={services?.ecosystem?.counters?.total_relationships_count}
                  isLoading={services?.ecosystem?.service_status !== "completed"}
                  toLink={buildLink("dados_cadastrais_ecossistema")}
                  labelLink={"Ver tudo"}
                />
              </Col>
              )}
              {services?.tst && (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounter
                  title={"Débitos trabalhistas"}
                  qtd={services?.tst?.counters?.total_processos_count}
                  isLoading={services?.tst?.service_status !== "completed"}
                  toLink={buildLink(ServicesEnum.tst.debitos_trabalhistas.name)}
                  labelLink={"Ver tudo"}
                />
              </Col>
              )}
              {services?.sintegra && (
              <Col xs={12} sm={12} md={8} lg={8}>
                <CardCounter
                  title={"Sintegra"}
                  qtd={services?.sintegra?.counters?.total_results_count}
                  isLoading={services?.sintegra?.service_status !== "completed"}
                  toLink={buildLink(state.data.target!.cpf_cnpj!.length <= 11 ? ServicesEnum.sintegra.sintegra_pf.name : ServicesEnum.sintegra.sintegra_pj.name)}
                  labelLink={"Ver tudo"}
                  currencyValue={services.sintegra?.counters?.total_results_count}
                />
              </Col>
              )}
            </Row>
          );
        },
      },
  ] : []), [state.data?.target?.id, reportState.data.files, state.status.getServiceCounters]);

  useEffect(() => {
    const servicesDispatched: string[] = []

    if (state.data.target?.services) {
        columns.forEach((item, idx) => {
          
          item?.services.forEach((key, idx) => {
            if(servicesDispatched.includes(key) || !state.data.target?.services[key]) return
            
            const service = state.data.target?.services[key];

            if(service.service_status !== "completed" && service.service !== ServicesEnum.veiculos.name) return;

            if (service?.dossier_id) {
              servicesDispatched.push(key)

              dispatch(
                getServiceCounterThunk({
                  service: service.service,
                  dossierId: service.dossier_id,
                })
              );
            }
          });

      });
    }
  }, [state.data.target?.id]);

  return (
    <TabContainer>
      <Row justify={"center"}>
        {columns.map((item, idx) => (
          <>
            {item.can_render && item?.render ? (
              <Col xs={24} sm={24} md={16} lg={16} className="row-counters">
                <div className="title">{item?.title}</div>
                <div className="content">
                  {state.data.target?.services ? (<>
                    {item?.render(state.data.target!.services)}
                  </>): <></>}
                </div>
              </Col>
            ) : null}
            <br />
          </>
        ))}
      </Row>
    </TabContainer>
  );
};

export { TabContentResumo };
