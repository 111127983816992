
import "./style.scss";

import { Table, Row, Col, Tooltip, Form, Input, Switch, FloatButton, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import Icons from "../../../../common/components/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getListReportsThunk,
  selectSearchListState,
  getReportThunk,
  getOrderThunk,
  resetGetOrder,
  resetCardToken,
  resetPurchaseOrder,
} from "./slice";
import StateStatus from "../../../../utils/stateStatus";
import localeValues from "antd/locale/pt_BR";
import { HeaderDash } from "../../../../common/components/header";
import { getProfileThunk, loginSelector } from "../../../login/slice";
import { formatCurrency } from "../../../../utils/formatters";
import { StatusIcon, StatusPaymentIcon } from "../../../../utils/status";
import CreateReportModal from "../../components/create_report_modal";
import PaymentModal from "../../components/payment_modal";
import PaymentResultModal from "../../components/payment_result_modal";
// @ts-ignore
import { ReactComponent as PlusImg } from "../../../../common/images/plus.svg";
// @ts-ignore
import { ReactComponent as Zap } from "../../../../common/images/zap.svg";
import ResumeReportModal from "../../components/resume_report_modal";
import { Link } from "react-router-dom";
import { ReportType } from "./interfaces";
import { ColumnType } from "antd/lib/table";
import EditClientModal from "../../components/edit_client_modal";
import { getListUsersThunk, selectListUsersState } from "../users/slice";
import { editClientThunk, selectListClientsState } from "../clients-adm/slice";

const ListReportsPage = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState<{type?: string | null, report?: ReportType | null}>({type: null});

  const state = useSelector(selectSearchListState);
  const usersListState = useSelector(selectListUsersState);
  const listClientsState = useSelector(selectListClientsState);
  const loginState = useSelector(loginSelector);
  const isAdmUser =
    loginState.data.profile?.is_staff || loginState.data.profile?.is_superuser;

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getProfileThunk());
    listReportsFirtsPage()
  }, []);

  useEffect(() => {
    if (state.status.getReport === StateStatus.succeeded) {
      dispatch(resetGetOrder());

      if(state.data.report?.order?.order_code) {
        dispatch(getOrderThunk(state.data.report.order?.order_code));
      }
    }
  }, [state.status.getReport])

  useEffect(() => {
    if (listClientsState.status.editClient === StateStatus.succeeded) {
      dispatch(getProfileThunk());
      listReportsFirtsPage()
      setModal({})
    }
  }, [listClientsState.status.editClient])

  useEffect(() => {
    if(loginState.data?.client && (!loginState.data?.client?.address?.cep || !loginState.data?.client?.address?.city)) {
      setModal({type: "edit-client"})
    } else if(modal?.type == "edit-client") {
      setModal({})
    }
  }, [loginState.status.getProfile])
  
  const listReportsFirtsPage = () => {
    dispatch(getListReportsThunk({
      page: 1,
      pageSize: 15,
      filter: null,
      onlyOwner: state.data.reports.filters.onlyOwner,
      status: null,
    }));
  }

  const viewReport = (report) => {
    return "/dashboard/report/" + report.id;
  };

  const viewReportStatus = (report) => {
    return "/dashboard/report/" + report.id + "/status";
  };

  interface IUser {
    key: number,
    name: string;
  }
  

  const columns: ColumnType<ReportType>[] = [
    {
      title: "Solicitação",
      dataIndex: "case_name",
      width: "20%",
      render: (_, record) => (
        <Row>
          {/* <span style={{fontSize: "14px"}}>{record.case_name}</span> */}
          {isAdmUser ? (
            <Col span={24}>
              {record.is_paid ? (
                <span className={"tag-monday not-overflow green"}>
                  PAGO
                </span>
              ) : record.billing_mode === 'mensal' ? (
                <span className={"tag-monday not-overflow gold"}>
                  MENSAL
                </span>
              ) : (
                <span className={"tag-monday not-overflow red"}>
                  NÃO PAGO
                </span>
              )}

            {!record.exists_on_monday ? (
                <span className={`tag-monday not-overflow ${(record.is_paid || record.billing_mode === 'mensal') ? "red" : ""}`}>
                  NÂO ENVIADO AO MONDAY
                </span>
              ): (
                <span className={"tag-monday not-overflow green link"} onClick={() => {
                  const url = `https://equipeinquest.monday.com/boards/3625535500/pulses/${record?.monday_item_id}`
                  window.open(url, "_blank")
                }}>
                  ABRIR NO MONDAY
                </span>
              )}

            {record.tags && record.tags?.map((value, idx) => (
              <span key={value} className={`tag-monday not-overflow blue`}>
                {value}
              </span>
            ))}
            </Col>
          ): null}
          <Col span={24}>{record.case_name}</Col>
          <Col span={24} className="info-report">
            <div className="hide-on-full-screen">
              <div style={{ marginBottom: "15px" }}>
                <StatusIcon status={record.status} isAdmUser={isAdmUser} />
              </div>
              <div style={{ marginBottom: "15px" }}>
                <StatusPaymentIcon status={record?.order?.status ?? ""} billingMode={record.billing_mode} />
              </div>
              <div>
                <Icons icon={"search"} /> {record.count_targets} pesquisado(s)
              </div>
              <div>
                <Tooltip title="Criado em">
                  <Icons icon={"clock"} /> {record.created_at}
                </Tooltip>
              </div>
            </div>
            {isAdmUser ? (
              <div>
                <Tooltip title="Cliente">
                  <Icons icon={"user-profile"} /> {record.client.name}
                </Tooltip>
              </div>
            ): null}
          </Col>
        </Row>
      ),
    },
    {
      title: "Serviço contratado",
      dataIndex: "case_type_display",
      responsive: ["lg", "md"],
      width: "20%",
      render: (_, record) => {
        return <span style={{ fontSize: "12px" }}>{record.case_type_display}</span>
      }
    },
    {
      title: "Data",
      dataIndex: "created_at",
      responsive: ["lg", "md"],
      width: "12%",
      render: (_, record) => {
        return <span style={{ fontSize: "12px" }}>{record.created_at}</span>
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["lg", "md"],
      width: "12%",
      render: (text) => <StatusIcon status={text} isAdmUser={isAdmUser} />,
    },
    {
      title: "Pagamento",
      dataIndex: "status",
      responsive: ["lg", "md"],
      width: "12%",
      render: (_, record) => {
        return <StatusPaymentIcon status={record?.order?.status ?? ""} billingMode={record.billing_mode}/>
      },
    },
    {
      title: "Pesquisados",
      dataIndex: "count_targets",
      responsive: ["lg", "md"],
      render: (text) => <span style={{ fontSize: "12px" }}>{text}</span>
    },
    {
      title: "Valor",
      dataIndex: "price",
      responsive: ["lg", "md"],
      width: "10%",
      render: (text) => <span style={{ fontSize: "12px" }}>{formatCurrency(text)}</span>,
    },
    {
      title: "Ações",
      dataIndex: "view",
      align: "center",
      width: "30%",
      // responsive: ["lg", "md"],
      render: (_, record) => (
        <Row gutter={[16, 16]} justify="center">
          {(!record.order || ["idle", "waiting", undefined, "error"].includes(record.order?.status ?? "")) && record.billing_mode !== "mensal" ? (
            <Col span={24}>
              <Tooltip title="Visualizar instruções de pagamento">
                <Link
                  to={"#"}
                  onClick={() => {
                    dispatch(resetGetOrder());
                    dispatch(resetCardToken());
                    dispatch(resetPurchaseOrder());
                    dispatch(getReportThunk(record.id))
                    setModal({ type: "payment-modal" });
                    listReportsFirtsPage()
                  }}
                >
                  <Icons
                    icon={"cart-shopping"}
                    className="icon-view"
                    style={{ fontSize: 20 }}
                  />
                  <span className="cart-item-price">
                    {formatCurrency(record?.price ?? 0)}
                  </span>
                </Link>
              </Tooltip>
            </Col>
          ) : null}
          
          {["completed"].includes(record.status) || isAdmUser ? (
            <>
              <Col>
                <Tooltip title="Visualizar busca">
                  <Link  to={viewReport(record)} target="_self">
                    <Icons
                      icon={"eye"}
                      className="icon-view"
                      style={{ fontSize: 20 }}
                    />
                  </Link>
                </Tooltip>
              </Col>
            </>
          ) : "-"}

          {isAdmUser ? (
            <Col>
              <Tooltip title="Página de status">
                <Link to={viewReportStatus(record)} target="_self">
                  <Icons
                    icon={"info-circle"}
                    className="icon-view"
                    style={{ fontSize: 16 }}
                  />
                </Link>
              </Tooltip>
            </Col>
          ) : null}
        </Row>
      ),
    },
  ];

  //listReports
  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (state.status.listReports) {
      case StateStatus.succeeded: {
        break;
      }
      case StateStatus.failed: {
        console.error(state.errMessage.listReports);
        break;
      }
    }
  }, [state.status.listReports]);


  const fetchListReports = (params) => {
    dispatch(
      getListReportsThunk({
        page: state.data.reports.pagination.page,
        pageSize: state.data.reports.pagination.pageSize,
        filter: state.data.reports.filters.filter,
        onlyOwner: state.data.reports.filters.onlyOwner,
        status: state.data.reports.filters.status,
        ...params,
      })
    );
  }


  return (
    <>
      {
        window.innerWidth <= 768 ? <FloatButton onClick={() => setModal({ type: "create-report-modal" })} icon={<PlusImg width="18px" />} type="primary" ></FloatButton> : null
      }
      <div className="list-reports-page">
        <HeaderDash />
        <div className="list-reports-content">
          <div className="welcome-title">
            Olá, {loginState.data?.profile?.name}
          </div>
          <Row justify={"space-between"} gutter={[16, 24]}>
          <Col>
            <Row justify="space-between" gutter={[16, 24]}>
              <Col>
                <button onClick={() => {
                  setModal({ type: "create-report-modal" });
                }} className="square-big-button">
                  <PlusImg />
                  SOLICITAR
                </button>
              </Col>
              <Col>
                <button onClick={() => {window.open("https://web.whatsapp.com/send?phone=5511948889788",'_blank');}} className="square-big-button-zap">
                  <Zap />
                  DÚVIDAS?
                </button>
              </Col>
            </Row>
            </Col>

            <Col>
              <div className="box-totalizer">
                <div>Total de solicitações</div>
                <div>
                  {
                    state.data ? state.data.reports.pagination.totalCount : 0
                  }
                </div>
              </div>
            </Col>
          </Row>

          <div className="buscas-box">
            <Form form={form}>
              <Row justify={"space-between"}>
                <Col xs={24} sm={24} md={5} lg={5}>
                  <Form.Item name={"search"}>
                    <Input.Search
                      // value={state.data.filters.filter}
                      placeholder="Buscar..."
                      loading={state.status.listReports === StateStatus.loading}
                      enterButton={true}
                      allowClear={true}
                      // onPressEnter={(event) => {
                      //   fetchListReports({page: 1, filter: event.target.value})
                      // }}
                      onSearch={(value) => {
                        fetchListReports({page: 1, filter: value})
                      }}
                    />
                  </Form.Item>
                </Col>
                {isAdmUser ? (
                <Col xs={24} sm={24} md={5} lg={5}>
                  <Form.Item
                    name="status"
                    label={"Status"}
                    initialValue={state.data.reports.filters.status}
                  >
                    <Select
                      allowClear={true}
                      onClear={() => {
                        fetchListReports({page: 1, status: null})
                      }}
                      onChange={(value) => {
                        fetchListReports({page: 1, status: value})
                      }}
                    >
                      <Select.Option value="idle">Fila de execução</Select.Option>
                      <Select.Option value="error">Erro</Select.Option>
                      <Select.Option value="loading">Realiz. pesquisa</Select.Option>
                      <Select.Option value="released">Liberado</Select.Option>
                      <Select.Option value="completed">Completo</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                ) : null}
                {isAdmUser ? (
                  <Col xs={24} sm={24} md={8} lg={6} style={{textAlign: "right"}} >
                    <Form.Item
                      name="only_owner"
                      label={
                        state.data.reports.filters.onlyOwner
                          ? "Exibindo apenas suas buscas"
                          : "Exibindo buscas de todos clientes"
                      }
                    >
                      <Switch
                        defaultChecked={state.data.reports.filters.onlyOwner}
                        onChange={(value) => {
                          fetchListReports({page: 1, onlyOwner: value})
                        }}
                      />
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
            </Form>
            <Table
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => {
              //       // viewReport(record);
              //     },
              //   };
              // }}
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={state.data.reports?.items ?? []}
              // onChange={onChangeTable}
              loading={state.status.listReports === StateStatus.loading}
              pagination={{
              showSizeChanger: false,
                current: state.data.reports.pagination?.page,
                pageSize: state.data.reports.pagination?.pageSize,
                total: state.data.reports.pagination?.totalCount,
                onChange: (page, pageSize) => {
                  fetchListReports({ page, pageSize })
                },
              }}
              locale={localeValues.Table}
            />
          </div>
        </div>
      </div>
      {modal.type === "create-report-modal" ? (
        <CreateReportModal
          editReport={modal?.report}
          onSuccess={() => {
            setModal({ type: "resume-report-modal" });
          }}
          onCancel={() => {
            setModal({});
            listReportsFirtsPage()
          }}
        />
      ) : null}
      {modal.type === "resume-report-modal" ? (
        <ResumeReportModal
          onSuccess={(values) => {
            listReportsFirtsPage()
            if (loginState.data.client?.billing_mode === "pre_pago") {
              setModal({ type: "payment-modal" });
            } else {
              setModal({});
            }
          }}
          onFailed={() => {}}
          onBack={() => {
            setModal({
              type: "create-report-modal",
              report: state.data.report,
            });
          }}
        />
      ) : null}
      {modal.type === "payment-modal" ? (
        <PaymentModal
          onSuccess={() => {
            setModal({ type: "payment-success" });
            listReportsFirtsPage()
          }}
          onFailed={() => {
            setModal({ type: "payment-error" });
            listReportsFirtsPage()
          }}
          onCancel={() => {
            setModal({});
            listReportsFirtsPage()
          }}
        />
      ) : null}
      {modal.type === "edit-client" && loginState.data.client ? (
        <EditClientModal
          allowEditCpfCnpj={false}
          isAdmUser={false}
          client={loginState.data.client}
          isLoading={listClientsState.status.editClient === StateStatus.loading}
          isVisible={true}
          onCancel={() => setModal({})}
          onSave={(values) => {
            dispatch(editClientThunk(values));
          }}
        />
      ) : null}
      {["payment-success", "payment-error"].includes(modal.type ?? "") ? (
        <PaymentResultModal
          //@ts-ignore
          type={modal.type}
          onClose={() => setModal({})}
        />
      ) : null}
    </>
  );
};

export default ListReportsPage;
