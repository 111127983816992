
import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Row, message } from "antd";
// import SelectSearchType from "../../common/components/select_search_type";
import InputCpfCnpjList from "../dashborad/components/create_report_modal/components/input-cpfcnpj-list";
import InputDocsList from "../dashborad/components/create_report_modal/components/input-docs-list";
import ContextOptions from "../dashborad/components/create_report_modal/components/context-options";
import CustomServicesOptions from "../dashborad/components/create_report_modal/components/custom-services-options";
import StateStatus from "../../utils/stateStatus";
import {
  createReportUnauthThunk,
  resetCreateReport,
  selectCreateReportUnauthState,
} from "./slice";

import { useDispatch, useSelector } from "react-redux";
import Input, { InputPhone } from "../../common/components/input";
import MaskInput from "../../common/components/mask-input";
// @ts-ignore
import logo from "../../common/images/logo_azul.png";
import "./styles.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { BlueSquareButton } from "../../common/components/button";
import { OptionType, searchOptions, SearchOptionType } from "../../common/components/select_search_type";
import { formatCurrency } from "../../utils/formatters";
import { ReportInfoType } from "../dashborad/components/create_report_modal";

const CreateReportUnauthPage = () => {
  const [reportInfo, setReportInfo] = useState<ReportInfoType | undefined>();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector(selectCreateReportUnauthState);
  const { caseType, optionId } = useParams();

  const opId = optionId?.replace(/\D/g, "")

  useEffect(() => {
    resetSelectionsForm();

    let option: OptionType | null = null;

    for (let idx = 0; idx < searchOptions.length; idx++) {
      const item = searchOptions[idx];

      for (let y = 0; y < item.options.length; y++) {
        const op = item.options[y];

        if (
          op.data.case_type.value === caseType &&
          op.value.toString() === opId
        ) {
          option = op;
          break;
        }
      }
    }

    if (option) {
      const values: ReportInfoType = {
        case_type: option.data.case_type.value,
        case_type_display: option.data.case_type.label,
        case_name: null,
        context: option.data.context,
        case_option: option.value,
        doc: option.data.doc,
        states: option.data.states,
        excludeStates: option.data.exclude_states,
        price: option.data.price,
      };

      resetSelectionsForm();
      // setFieldsToEdit(editReport);
      setReportInfo({...(reportInfo ?? {}), ...values});
    }
  }, [caseType, opId]);

  useEffect(() => {
    if (state.status.createReportUnauth === StateStatus.succeeded && state.data?.redirect_url) {
      message.success("Você será redirecionado para o pagamento...");
      window.open(state.data.redirect_url, "_self");
      dispatch(resetCreateReport());
    }

    if (state.status.createReportUnauth === StateStatus.failed) {
      message.error("Falha ao criar o caso.");
    }
  }, [state.status.createReportUnauth]);

  
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const entries = Object.fromEntries(query.entries());
    if(entries.coupon) {
      apllyCoupon(entries.coupon);
    }
    form.setFieldsValue(entries);
  }, [reportInfo?.price]);

  const apllyCoupon = (coupon) => {
    if(coupon) {
      if(coupon === "CAROL10OFF" && reportInfo?.case_type === "smart_pro" && reportInfo?.price && !reportInfo?.coupon) {
        let percent = 0.1
        console.log({price: reportInfo.price, percent})
        setReportInfo({
          ...reportInfo, 
          price: reportInfo.price, 
          discount_price: reportInfo.price - (reportInfo.price * percent), 
          coupon,
        });
      }
    }
  }

  const onFinish = (values) => {
    dispatch(createReportUnauthThunk(values));
  };

  // const disclaimer = (disclaimerTitle, disclaimerText) => {
  //   return (
  //     <div className="label">
  //       <div className="disclaimer-title">{disclaimerTitle}</div>
  //       <div className="disclaimer">
  //         <div className="disclaimer-bar"></div>
  //         <div className="disclaimer-text">{disclaimerText}</div>
  //       </div>
  //       {/* {labelText} */}
  //     </div>
  //   );
  // };

  // const disclaimerRender = (caseType) => {
  //   switch (caseType) {
  //     case "docs":
  //       return disclaimer(
  //         <>
  //           Você selecionou o <strong>"DOCS"</strong>
  //         </>,
  //         "Este serviço é ideal caso você precise buscar ou adquirir documentos como matrículas, escrituras e procurações."
  //       );
  //     case "preventivo":
  //       return disclaimer(
  //         <>
  //           Você selecionou o <strong>"REPORT PREVENTIVO"</strong> que tem um
  //           preço de <strong>R$ 35,00</strong> por CPF/CNPJ
  //         </>,
  //         "O diferencial do preventivo é a criação de um parecer final via IA e algoritmos cognitivos, nele resumimos tudo que é importante para a sua tomada de decisão e preveni-lo de possíveis fraudes antes de iniciar uma nova relação com a pessoa ou empresa pesquisada. O preventivo não realiza busca de bens materiais do pesquisado, como veículos e imóveis."
  //       );

  //     case "custom":
  //       return disclaimer(
  //         <>
  //           Você selecionou o <strong>"REPORT PERSONALIZADO"</strong> que tem um
  //           preço de <strong>R$ 10,90</strong> por CPF/CNPJ
  //         </>,
  //         "Selecione as buscas mais importantes para investigar uma pessoa, com este REPORT PERSONALIZADO você consegue ter um custo reduzido selecionando somente as informações necessárias."
  //       );

  //     case "simples":
  //       return disclaimer(
  //         <>
  //           Você selecionou o <strong>"REPORT SIMPLES"</strong> que tem um preço
  //           de <strong>R$ 68,00</strong> por CPF/CNPJ
  //         </>,
  //         "O Report Simples te entrega a busca mais completa, com ele você tem acesso a todas as informações disponíveis sobre a pessoa ou empresa pesquisada. A pesquisa imobiliária estadual é apenas em São Paulo."
  //       );

  //     case "smart":
  //       return disclaimer(
  //         <>
  //           Você selecionou o <strong>"REPORT SMART"</strong> que tem um preço
  //           de <strong>R$ 578,00</strong> por CPF/CNPJ
  //         </>,
  //         "Uma solução integrada para análise de ativos e perfis jurídicos, com análise completa de processos judicias, busca de ativos imobiliários por região do pesquisado e relacionamentos juntamente com um parecer técnico."
  //       );

  //     case "smart_pro":
  //       return disclaimer(
  //         <>
  //           Você selecionou o <strong>"REPORT SMART PRO"</strong> que tem um
  //           preço de <strong>R$ 997,00</strong> por CPF/CNPJ{" "}
  //         </>,
  //         "A mais completa solução integrada para análise de ativos e perfis jurídicos, com análise completa de processos judicias, busca de ativos imobiliários em todo o Brasil com mapa de calor e relacionamentos juntamente com um parecer técnico."
  //       );

  //     case "mapa_calor":
  //       return disclaimer(
  //         <>
  //           Você selecionou o <strong>"MAPA DE CALOR"</strong> que tem um preço
  //           de <strong>R$ 415,00</strong> por CPF/CNPJ{" "}
  //         </>,
  //         "A solução para encontrar escrituras e procurações do investigado em todo o terrintório nacional."
  //       );

  //     default:
  //       return null;
  //   }
  // };

  // const setFieldsToEdit = (data) => {
  //   if (data) {

  //     const targets = data?.targets.map((item) => ({
  //       cpf_cnpj: item.cpf_cnpj,
  //     }));

  //     setReportInfo({
  //       case_name: data?.case_name,
  //       case_type: data?.case_type,
  //       case_type_display: data?.case_type_display,
  //       case_option: data?.case_option,
  //       doc:
  //         data?.docs_products?.length > 0
  //           ? data?.docs_products[0].product
  //           : null,
  //       context: data.context,
  //       context_data: (data?.context) ? data[data?.context] : null,
  //       targets: targets,
  //       docs_products: data?.docs_products,
  //     });

  //     form.setFieldsValue({
  //       // targets: targets,
  //       // docs_products: data?.docs_products,
  //       case_option: data?.case_option,
  //       context: data?.context,
  //       case_name: data?.case_name,
  //       services: data?.services,
  //     });

  //     if (data?.context && data[data?.context]) {
  //       form.setFieldValue([data.context], data[data.context]);
  //     }
  //   }
  // };

  const resetSelectionsForm = () => {
    form.setFieldValue(["context"], null);
    form.setFieldValue("docs_products", [{ qtd: null, product: null }]);
    form.setFieldValue(["general", "focus_city"], []);
    form.setFieldValue(["judicial_recovery", "focus_city"], []);
    form.setFieldValue(
      ["due_dilligence_merge_and_acquisition", "focus_city"],
      []
    );
    form.setFieldValue(["due_dilligence_general", "focus_city"], []);
    form.setFieldValue(["compliance", "focus_city"], []);
    form.setFieldValue(["divorce", "focus_city"], []);
    form.setFieldValue(["credit_analysis", "focus_city"], []);
    form.setFieldValue(["docs", "focus_city"], []);
  };

  // open={true}
  //     onCancel={onCancel}
  //     footer={[
  //       <Row justify="end" style={{ marginTop: "30px" }}>
  //         {/* <BlueSquareOulinedButton
  //           onClick={onCancel}>
  //           Cancelar
  //         </BlueSquareOulinedButton> */}

  //         <BlueSquareButton
  //           onClick={() => form.submit()}
  //           isLoading={
  //             state.status.calcReportPrice === StateStatus.loading ||
  //             state.status.createReportUnauth === StateStatus.loading
  //           }
  //           disabled={
  //             state.status.calcReportPrice === StateStatus.loading ||
  //             state.status.createReportUnauth === StateStatus.loading
  //           }
  //         >
  //           Revisar caso
  //         </BlueSquareButton>
  //       </Row>,
  //     ]}
  //     width={"900px"}

  return (
    <div className="create-report-unauth">
      <Row className="content-create-report-unauth" justify="center">
        <Col xs={24} sm={24} md={12}>
          <Row justify={"center"}>
            <Col className="page-logo" span={24}>
                <img src={logo} alt="Inquest" />
                <Divider />
            </Col>
          </Row>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "18px",
              marginBottom: "30px",
              marginTop: "15px",
            }}
          >
            {reportInfo?.case_type_display ?? ""}
            <br />
            <span style={{ fontSize: "12px" }}>
              <div>
              {reportInfo?.discount_price 
                ? (<s>
                {formatCurrency(reportInfo.price ?? 0)} por pesquisado 
                </s>) : null}
              </div>
              <div>
              {reportInfo?.price 
                ? (<> 
                {formatCurrency(reportInfo.discount_price ? reportInfo.discount_price : reportInfo.price)} por pesquisado 
                </>) : null}
              </div>
            </span>
          </div>
          <Form
            form={form}
            layout={"vertical"}
            // initialValues={{
            //   // targets: reportInfo?.targets,
            //   targets: [{cpf_cnpj: "113.671.836-26"}, {cpf_cnpj: "113.671.836-26"}],
            // }}
            onFinish={(values) => {
              let docs = [];
              if (reportInfo!.case_type === "docs") {
                docs = values.docs_products?.map((item) => {
                  return {
                    ...item,
                    product: reportInfo!.doc,
                  };
                });
              }

              const data = {
                ...values,
                client: values.client,
                case_type: reportInfo!.case_type,
                case_type_display: reportInfo!.case_type_display,
                context: reportInfo!.context,
                case_option: reportInfo!.case_option,
                case_name: values.case_name ?? "",
                targets: values.targets,
                docs_products: docs,
                services: values.services,
              };

              if (values.context) {
                data.context = values.context;
                data[values.context] = values[values.context];
              }

              onFinish(data);
            }}
          >
            {/* <Form.Item
          name={"case_option"}
          // label="1. Vamos começar: Qual é o seu objetivo?"
          rules={[
            {
              required: true,
              message: (
                <div className="normal-12">Informe uma opção válida!</div>
              ),
            },
          ]}
        >
          <div className="modal-step-name">
            1. Vamos Começar: Qual o seu objetivo?
          </div>
          <SelectSearchType
            defaultValue={reportInfo?.case_option ?? undefined}
            listHeight={400}
            onChange={(option) => {
              const values = {
                case_type: option.data.case_type.value,
                case_type_display: option.data.case_type.label,
                case_name: null,
                context: option.data.context,
                case_option: option.value,
                doc: option.data.doc,
                states: option.data.states,
                excludeStates: option.data.exclude_states,
              };

              resetSelectionsForm();
              // setFieldsToEdit(editReport);
              setReportInfo(values);

              form.setFieldValue("case_option", option.value);
              form.setFieldValue("context", option.data.context);
            }}
          />
        </Form.Item> 
        <br />
        */}

            {/* {reportInfo?.case_type ? disclaimerRender(reportInfo.case_type) : null}
        <br /> */}

            <Form.Item name="utm_source" noStyle>
              <input type="hidden" />
            </Form.Item>
            <Form.Item name="utm_medium" noStyle>
              <input type="hidden" />
            </Form.Item>
            <Form.Item name="utm_campaign" noStyle>
              <input type="hidden" />
            </Form.Item>
            <Form.Item name="utm_term" noStyle>
              <input type="hidden" />
            </Form.Item>
            <Form.Item name="utm_content" noStyle>
              <input type="hidden" />
            </Form.Item>
            <Form.Item name="coupon" noStyle>
              <input type="hidden" />
            </Form.Item>

            <div className="modal-step-name">
              1. Dados pessoais ou empresarial para faturamento do pedido:{" "}
              <br />
            </div>
            <Form.Item
              name={["client", "cpf_cnpj"]}
              label="CPF ou CNPJ para faturamento"
              initialValue=""
              rules={[
                () => ({
                  validator(_, value) {
                    if (
                      value !== undefined &&
                      value !== null &&
                      value.length > 0
                    ) {
                      const val = value.replace(/[^0-9]+/g, "");

                      if (val.length > 11) {
                        if (cnpj.isValid(val)) return Promise.resolve();

                        return Promise.reject(new Error("CNPJ inválido"));
                      }

                      if (!cpf.isValid(val))
                        return Promise.reject(new Error("CPF inválido"));

                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error("Informe um CPF ou CNPJ")
                      );
                    }
                  },
                }),
              ]}
            >
              <MaskInput
                maskOptions={{
                  dispatch: function (appended, dynamicMasked) {
                    const value = dynamicMasked.unmaskedValue;
                    const isCPF =
                      value?.length <= 11 &&
                      [undefined, null, ""].includes(appended);
                    return dynamicMasked.compiledMasks[isCPF ? 0 : 1];
                  },
                }}
                mask={[
                  {
                    mask: "000.000.000-00",
                    lazy: true,
                  },
                  {
                    mask: "00.000.000/0000-00",
                    lazy: true,
                  },
                ]}
                placeholder="Insira seu CPF ou CNPJ"
              />
            </Form.Item>
            <Form.Item
              name={["client", "name"]}
              label="Nome ou Razão Social"
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Campo obrigatório</div>,
                },
                {
                  max: 100,
                  message: (
                    <div className="normal-12">Mínimo de 100 caracteres</div>
                  ),
                },
              ]}
            >
              <Input placeholder="Insira o Nome ou Razão Social" />
            </Form.Item>

            <Form.Item
              name={["client", "email"]}
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Campo obrigatório</div>,
                },
              ]}
            >
              <Input type="email" placeholder="Insira seu e-mail" />
            </Form.Item>

            <Form.Item
              name={["client", "phone"]}
              label="Telefone"
              initialValue={""}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Informe um telefone!",
                },
                {
                  min: 10,
                  max: 11,
                  message: "Informe um telefone válido!",
                },
              ]}
            >
              <InputPhone style={{ width: "100%" }} />
            </Form.Item>

            <br />
            {!reportInfo?.case_type ? (
              <>
                Opção selecionada "{caseType}" e "{opId}" não existe. Opções
                disponíveis:
                <br />
                {searchOptions.map((item, i) => (
                  <ul key={i}>
                    {item.options.map((op, y) => (
                      <li key={y}>
                        <Link
                          to={`/create-report/${op.data.case_type.value}/${op.value}`}
                        >
                          /{op.data.case_type.value}/{op.value}
                        </Link>{" "}
                        - {op.data.case_type.label}{" "}
                        {op.data.context
                          ? `| Contexto: ${op.data.context}`
                          : ""}{" "}
                        {op.data.doc ? `| Doc: ${op.data.doc}` : ""}
                      </li>
                    ))}
                  </ul>
                ))}
              </>
            ) : (
              <>
                <div className="modal-step-name">
                  2. Dê um nome para esta solicitação: <br />
                  <p className="help-text">
                    Exemplos: <br />
                    Caso Família Silva; Caso João Gabriel Silva; Caso Nome
                    Empresa LTDA;
                    <br />
                  </p>
                </div>
                <Form.Item
                  name={"case_name"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="normal-12">Campo obrigatório</div>
                      ),
                    },
                    {
                      min: 15,
                      message: (
                        <div className="normal-12">Mínimo de 15 caracteres</div>
                      ),
                    },
                    {
                      max: 150,
                      message: (
                        <div className="normal-12">
                          A identificação não pode ultrapassar 150 caracteres.
                        </div>
                      ),
                    },
                  ]}
                >
                  <Input placeholder="Ex.: Caso Família Silva; Caso João Gabriel Silva; Caso Nome Empresa LTDA;" />
                </Form.Item>
                <br />

                {reportInfo?.case_type === "docs" ? (
                  <InputDocsList
                    form={form}
                    states={reportInfo.states}
                    excludeStates={reportInfo.excludeStates}
                    name={"docs_products"}
                    service={reportInfo.doc ?? ""}
                    label={
                      <div className="modal-step-name">
                        3. Adicione pessoas físicas e jurídicas a serem
                        pesquisadas:
                      </div>
                    }
                    initialValue={reportInfo.docs_products}
                  />
                ) : (
                  <InputCpfCnpjList
                    form={form}
                    name={"targets"}
                    label={
                      <div className="modal-step-name">
                        3. Adicione pessoas físicas e jurídicas a serem
                        pesquisadas:
                      </div>
                    }
                    initialValue={reportInfo.targets ? reportInfo.targets.map(x =>  x as any) : []}
                  />
                )}

                {reportInfo?.case_type === "custom" ? (
                  <>
                    <div className="modal-step-name">
                      4. Selecione ao menos uma informação extra a ser buscada:
                    </div>
                    <span style={{ fontSize: "12px" }}>
                      * Dados de cadastro (nome completo, endereços, contatos,
                      Receita Federal) e o ecossistema do pesquisado{" "}
                      <strong>já estão inclusos.</strong>
                    </span>
                    <br />
                    <br />
                    <CustomServicesOptions name={"services"} label="" />
                  </>
                ) : null}

                {["smart", "smart_pro"].includes(reportInfo?.case_type) ? (
                  <>
                    <br />
                    <div className="modal-step-name">
                      4. Selecione qual o contexto da pesquisa
                    </div>
                    <ContextOptions
                      form={form}
                      initialValue={reportInfo?.context}
                      initialData={reportInfo?.context_data}
                      name={"context"}
                      // label="4. Selecione qual o contexto da pesquisa"
                      caseType={reportInfo?.case_type}
                    />
                  </>
                ) : null}

                <br />
                <Row justify={"end"}>
                  <Col>
                    <BlueSquareButton
                      onClick={() => form.submit()}
                      isLoading={
                        state.status.createReportUnauth === StateStatus.loading
                      }
                      disabled={
                        state.status.createReportUnauth === StateStatus.loading
                      }
                    >
                      Pagar
                    </BlueSquareButton>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default CreateReportUnauthPage;
